import { BasketItem, CatalogueType } from "@/api"
import { CartItem } from "@/hooks/useCart"
import { Product, Service } from "@/types"
import { getCloudfrontImageUrl } from "@/utils/catalogue"

export const capitalize = (value: string) => value && String(value[0]).toUpperCase() + String(value).slice(1)

export const mapCartItemsToBasketItems = (
	cartItems: CartItem[],
	catalogueItems: (Product | Service)[],
): { basketItems: BasketItem[]; invalidCartItems: CartItem[] } => {
	if (cartItems.length === 0 || catalogueItems.length === 0) {
		return { basketItems: [], invalidCartItems: [] }
	}

	const invalidCartItems: CartItem[] = []
	const basketItems = cartItems.reduce<BasketItem[]>((acc, cartItem) => {
		const sourceItem = catalogueItems.find((item) => item.catalogueItemID === cartItem.id)
		if (!sourceItem) {
			invalidCartItems.push(cartItem)
			return acc
		}

		let name = sourceItem.name
		let price = sourceItem.price

		if (cartItem.selectedVariantId) {
			if ("variants" in sourceItem && Array.isArray(sourceItem.variants)) {
				const variant = sourceItem.variants.find((v) => `${v.catalogueItemID}.${v.variantID}` === cartItem.selectedVariantId)
				if (!variant) {
					invalidCartItems.push(cartItem)
					return acc
				}
				const variantValues = Object.values(variant.variantValues).join(", ")
				name = `${sourceItem.name} - ${variantValues}`
				price = variant.price
			} else {
				invalidCartItems.push(cartItem)
				return acc
			}
		}

		const imageUrl = getCloudfrontImageUrl(sourceItem.imageList?.thumbnail?.[0] ?? undefined)
		const catalogueType = sourceItem.catalogueType === "PRODUCT" ? CatalogueType.PRODUCT : CatalogueType.SERVICE

		acc.push({
			name,
			pricePerUnit: Number((price * 100).toFixed(2)),
			quantity: cartItem.quantity,
			description: sourceItem.description,
			catalogueType,
			imageUrl,
			isVatApplicable: sourceItem.isVatApplicable,
		})

		return acc
	}, [])

	return { basketItems, invalidCartItems }
}
